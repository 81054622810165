import {deleteItem, getRequest, postRequest, putRequest, updateItem} from '../peticionesService';
import urlApi from '../../api';
import {IAprobaciones, IFormPostcontractual, IForo, IPostcontractual} from '../../models/postcontractual/postcontractualModel';
import {mainTypeMsj} from '../../../shared/contexts/interfaces/mainInterfaces';
import moment from 'moment';
import {ILiquidacion} from '../../models/postcontractual/liquidacionModel';
import {ISuspension} from '../../models/postcontractual/suspensionModel';
import {IUsuarios} from '../../models/usuarios/usuariosModel';
import {IContraCondicionesPago} from '../../models/contractual/condicionesPagoModel';
import {IContraUsuarios, IFormContraUsuarios} from '../../models/contractual/usuariosModel';
import {formatStringtoDateString} from '../../../shared/helpers/Fecha';
import {useUpdateRequest} from "../../../shared/hooks/useUpdate";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerProcesosPostcontratuales = async (params?: any): Promise<IPostcontractual[]> => {
  let data: IPostcontractual[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POSTCONTRACTUAL, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por criterio en la bd
 */
export const obtenerPostUsuarioByCriterio = async (id: number, params?: any): Promise<any> => {
  let data: any = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_POST_USUARIOS}${id}`, params);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerPostcontractualUsuarios = async (filter?: string, valuefilter?: string): Promise<IContraUsuarios[]> => {
  let data: IContraUsuarios[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POSTCONTRACTUAL_USUARIOS, filter ? {
      filter: filter,
      valuefilter: valuefilter
    } : {});
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerProcesoPostcontractual = async (id: number): Promise<any> => {
  let data: any = undefined;
  try {
    const resp = await getRequest(`${urlApi.SHOW_POSTCONTRACTUAL}${id}`);
    data = {
      ...resp.data.record,
      liquidaciones: resp.data?.liquidacion.map((liquidacion: ILiquidacion) => ({
        ...liquidacion,
        terminacionanticipada: (liquidacion.terminacionanticipada && liquidacion.terminacionanticipada === 1),
        fecha: formatStringtoDateString(liquidacion?.fecha),
      })),
      adendas: resp.data?.adendas,
      suspensiones: resp.data?.suspension.map((suspension: ISuspension): ISuspension => ({
        ...suspension,
        idsolicitante: (suspension?.idsolicitante) ? `${(suspension.idsolicitante as IUsuarios)?.nombres} ${(suspension.idsolicitante as IUsuarios)?.apellidos}` : '',
        inicio: formatStringtoDateString(suspension?.inicio).toString(),
        final: formatStringtoDateString(suspension?.final).toString(),
        solicitud: formatStringtoDateString(suspension?.solicitud).toString(),
      })),
      renovaciones: resp.data?.renovacion,
      polizas: resp?.data?.polizas || [],
      reinicios: resp?.data?.reinicio || [],
    };
  } catch (error) {
    console.error(error);
  }

  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentariosForo = async (params?: any): Promise<IForo[]> => {
  let data: IForo[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_COMMENTS_POSTCONTRACTUAL, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarComentarioForo = async (
  values: IForo,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMMENT_POSTCONTRACTUAL;
  return await updateItem(url, values, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const crearProcesoPoscontractual = async (
  values: IFormPostcontractual,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<number | null> => {
  const url = urlApi.CREAR_POSTCONTRACTUAL;
  let data: number | null = null;
  try {
    const resp = await postRequest(url, values);
    if (resp.data.code === 200) {
      data = parseInt(resp.data.id);
      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const editarProcesoPoscontractual = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean> => {
  const url = `${urlApi.EDIT_POSTCONTRACTUAL}${values.id}`
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const aprobarAprobador = async (
  data: any,
  id: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = `${urlApi.APROBAR_POST_APROBADOR}${id}`;
  let success = false;
  try {
    const resp: any = await putRequest(url, data);
    if (resp?.data?.code === 200) {
      success = true;
      sendNotification && notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const aprobarActa = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = urlApi.POST_APROBAR_ACTA
  return await updateItem(url, values, notification, sendNotification);
};

export const aprobarProceso = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = (values?.id) ? `${urlApi.APROBAR_PROCESO_POST_EDIT}${values?.id}` : `${urlApi.APROBAR_PROCESO_POST}`;
  let success = false;
  try {
    if (await updateItem(url, values, notification)) {
      success = true;
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerListaUsuariosAprob = async (id: string | number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(`${urlApi.LIST_USUARIOS_APROBACIONES_POST}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentarios = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.COMENT_POSTCONTRACTUAL;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const crearComentario = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMENT_POSTCONTRACTUAL;
  return await updateItem(url, values, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */

export const guardarPostcontractualUsuarios = async (
  values: IFormContraUsuarios,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_POSTCONTRACTUAL_USUARIOS : `${urlApi.EDIT_POSTCONTRACTUAL_USUARIOS}${values.id}`
  return await updateItem(url, values, notification, false, sendNotification);
};

export const useObtenerProcesosDinamicosPostContractuales = (
  handle = false,
  params?,
  body?
) => {
  const [guardarService] = useUpdateRequest();

  const handleGetPostContractual = async (
    body: any,
    requestData = true
  ): Promise<any> => {
    return guardarService({
      url: urlApi.DATATABLE_POSTCONTRACTUAL,
      contentType: "application/json",
      sendNotification: false,
      body,
      requestData,
    });
  };

  return {
    handleGetPostContractual,
  };
};

export const obtenerListaFiltros = async () => {
  let data = [];
  try {
    const resp = await getRequest(urlApi.LIST_FILTRO_POSTCONTRACTUAL);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
}

export const borrarPostContractualUsuarios = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.EDIT_POSTCONTRACTUAL_USUARIOS}${id}`;
  return await deleteItem(url, notification);
};

export const obtenerAprobaciones = async (params?: any): Promise<IAprobaciones[]> => {
  let data: IAprobaciones[] = [];
  try {
    const resp = await getRequest(urlApi.APROBAR_PROCESO_POST_LIST, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};