import { useFormikContext } from 'formik';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useMainContext } from '../contexts/mainContext';
import { guardarAnexos } from '../../core/services/documentos/anexosService';
import { IAnexos } from '../../core/models/documentos/anexosModel';
import ButtonCustom from './ButtonCustom';
import dev from '../../core/enviroments';
import { downloadFile } from '../helpers/generales';

const UniqueFileField: FC<IUniqueFileField> = ({ 
  invalid = false,
  disabled = false,
  category,
  modulo = null,
  idmodulo = null,
  record = null,
  ...props
}) => {
  const { setFieldValue, getFieldProps, setFieldTouched } = useFormikContext();
  const { mainDispatch, handleNotification } = useMainContext();
  const inputElement = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [anexo, setAnexo] = useState<IAnexos|null>(record);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const values = {
      id: null,
      entidad: null,
      identidad: null,
      modulo,
      idmodulo,
      directorio: null,
      nombrearchivo: null,
      extension: null,
      nombreoriginal: e.target.files ? e.target.files[0].name : null,
      activo: 2,
      archivo: e.target.files ? e.target.files[0] : null,
      idcategoria: category,
    };
    const record = await guardarAnexos(values, handleNotification);
    if (record) {
      setAnexo(record);
      setFieldValue(props.name, record.id);
      if (inputElement.current) {
        inputElement.current.value = '';
      }
    }
    setFieldTouched(props.name, true);
    setLoading(false);
  }

  const handleSelectFile = () => {
    inputElement.current?.click();
  }

  const handleRemove = () => {
    setAnexo(null);
    setFieldValue(props.name, 0);
  }

  const handleDownload = () => {
    if (anexo) {
      downloadFile(
        `${dev.URL_SERVER}/uploads/${anexo?.directorio}/${anexo?.nombrearchivo}.${anexo?.extension}`,
        anexo.nombreoriginal || ''
      );
    }
  }

  const handleView = () => {
    window.open(
      `${dev.URL_SERVER}/uploads/${anexo?.directorio}/${anexo?.nombrearchivo}.${anexo?.extension}`,
      '_blank'
    )
  }

  return (
    <>
      <input
        type="file"
        onChange={handleUpload}
        className="d-none"
        ref={inputElement}
        accept="*/*"
      />
      <InputGroup className={invalid ? "unique-file-field is-invalid" : "unique-file-field"}>
        <Input
          placeholder={t('No se ha seleccionado')}
          {...props}
          value={anexo?.nombreoriginal || ''}
          disabled
          record={undefined}
        />
        {!disabled && !anexo && (
          <InputGroupAddon addonType="append" tag="div">
            <ButtonCustom
              type="button"
              onClick={handleSelectFile}
              disabled={loading}
              loading={loading}
            >
              <i className="uil-mr uil-file-upload"></i>
              {t('Cargar')}
            </ButtonCustom>
          </InputGroupAddon>
        )}
        {!!anexo && (
          <InputGroupAddon addonType="append" tag="div">
            <>
              <ButtonCustom
                type="button"
                onClick={handleView}
                disabled={loading}
                loading={loading}
                title={t('Ver documento')}
                outline
              >
                <i className="uil-eye"></i>
              </ButtonCustom>
              <ButtonCustom
                type="button"
                onClick={handleDownload}
                disabled={loading}
                loading={loading}
                title={t('Descargar documento')}
                outline
              >
                <i className="uil-file-download"></i>
              </ButtonCustom>
              {!disabled && (
                <ButtonCustom
                  type="button"
                  onClick={handleRemove}
                  disabled={loading}
                  loading={loading}
                  title={t('Borrar')}
                  outline
                >
                  <i className="uil-times"></i>
                </ButtonCustom>
              )}
            </>
          </InputGroupAddon>
        )}
      </InputGroup>
    </>
  )
}

interface IUniqueFileField {
  field: any;
  category: string;
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
  id?: string;
  modulo?: string | null;
  idmodulo?: number | null;
  record?: IAnexos | null;
}

export default UniqueFileField;
