import React, { FC, useEffect, useMemo, useState } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import * as Yup from 'yup';
import { EEstadosPre, FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IProveedores, IProveedoresForm } from '../../../../core/models/precontractual/proveedoresModel';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { falseLoading, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { guardarProveedores, mostrarProveedores } from '../../../../core/services/precontractual/proveedoresService';
import UniqueFileField from '../../../../shared/components/UniqueFileField';
import { useUserContext } from '../../../../shared/contexts/userContext';
import DateField from '../../../../shared/components/DateField';
import UniqueSelectField from '../../../../shared/components/UniqueSelectField';
import { listSiNo } from '../../../../core/services/listasDesplegables';

const FormProveedor: FC<IFormProveedor> = ({ record, negociaciones = false, adjudicacion = false }) => {
  const { cambiarEstado, estado, proceso } = usePrecontractualContext();
  const { t } = useTranslation();
  const { mainDispatch, handleNotification, mainState } = useMainContext();
  const { userState } = useUserContext();

  const [enviado, setEnviado] = useState(false);
  const [proveedor, setProveedor] = useState(record);

  const habilitar = useMemo(() => {
    if (estado === EEstadosPre.CARGUE_RENEGOCIACIONES && !userState.isAutenticated) {
      return true;
    } else if (estado === EEstadosPre.ACEPTACION_ADJUDICACION && !userState.isAutenticated) {
      return true;
    } else {
      return false;
    }
  }, [estado]);

  const obtenerProveedor = async () => {
    const data = await mostrarProveedores(record?.id ?? 0);
    if (data) {
      setProveedor(data);
    }
  }

  useEffect(() => {
    obtenerProveedor();
  }, []);

  const enviarPropuesta = async () => {
    await cambiarEstado();
    setEnviado(true);
  }

  const onSubmit = async (values: IProveedoresForm) => {
    mainDispatch(trueLoading());
    await guardarProveedores(values, handleNotification);
    await obtenerProveedor();
    mainDispatch(falseLoading());
  };

  const initialValues: IProveedoresForm = {
    id: proveedor?.id,
    idprecontractual: proceso?.id ?? 0,
    finnegociacion: proveedor?.finnegociacion ?? '',
    observaciones: proveedor?.observaciones ?? '',
    iddocnegociacion: proveedor?.iddocnegociacion?.id ?? 0,
    idnuevaoferta: proveedor?.idnuevaoferta?.id ?? 0,
    iddocadicional: proveedor?.iddocadicional?.id ?? 0,
    iddocadicional1: proveedor?.iddocadicional1?.id ?? 0,
    iddocadicional2: proveedor?.iddocadicional2?.id ?? 0,
    iddocadicional3: proveedor?.iddocadicional3?.id ?? 0,
    acepta: proveedor?.acepta,
  } as IProveedoresForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    idnuevaoferta: negociaciones ?
      Yup.number().required(FormValidation.required).min(1, FormValidation.required).nullable() : Yup.number().nullable(),
    iddocadicional: Yup.number().nullable(),
    iddocadicional1: Yup.number().nullable(),
    iddocadicional2: Yup.number().nullable(),
    iddocadicional3: Yup.number().nullable(),
    acepta: adjudicacion ?
      Yup.number().required(FormValidation.required).min(1, FormValidation.required).nullable() : Yup.number().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormProveedor" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        {negociaciones && (
          <>
            {!userState.isAutenticated && (
              <>
                <Alert color="warning">
                  {t(`Señor Oferente, todo lo que sea cargado a la herramienta quedará guardado 
                  inmediatamente y será visualizado por el equipo de Compras Negociador.`)}
                </Alert>
                <Alert color="warning">
                  {t('Restricción para subir archivos máximo a 25MB.')}
                </Alert>
              </>
            )}
            <Row>
              <Col sm={12} md={{ size: 6, offset: 3 }}>
                <FormGroup>
                  <Label>{t('Fecha y Hora Máxima Recepción Ofertas')}</Label>
                  <Field
                    as={DateField}
                    name="finnegociacion"
                    invalid={errors.finnegociacion && touched.finnegociacion}
                    disabled
                    hora
                  />
                  <FormFeedback>{errors.finnegociacion && t(errors.finnegociacion)}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={{ size: 6, offset: 3 }}>
                <FormGroup>
                  <Label>{t('Observaciones')}</Label>
                  <Field
                    as={Input}
                    name="observaciones"
                    invalid={errors.observaciones && touched.observaciones}
                    disabled
                    type="textarea"
                  />
                  <FormFeedback>{errors.observaciones && t(errors.observaciones)}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={{ size: 6, offset: 3 }}>
                <FormGroup>
                  <Label>{t('Cargue Documento Negociación')}</Label>
                  <Field
                    as={UniqueFileField}
                    name="iddocnegociacion"
                    invalid={errors.iddocnegociacion && touched.iddocnegociacion}
                    disabled
                    category="negociacion"
                    modulo="PrePrecontractual"
                    idmodulo={proceso?.id}
                    record={proveedor?.iddocnegociacion}
                  />
                  <FormFeedback>{errors.iddocnegociacion && t(errors.iddocnegociacion)}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <Table>
                  <thead>
                    <tr>
                      <th align="center">{t('Tipo Documento')}</th>
                      <th align="center">{t('Descripción')}</th>
                      <th align="center">{t('Obligatorio')}</th>
                      {habilitar && (
                        <th align="center">{t('Acción')}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ borderBottom: '1px solid #ccc' }}>
                      <td align="center">
                        {t('Nueva Oferta Económica')}
                      </td>
                      <td align="center">
                        {t('Oferta Económica o Cotizaciones')}
                      </td>
                      <td align="center">
                        {t('Si')}
                      </td>
                      {habilitar && (
                        <td>
                          <FormGroup>
                            <Field
                              as={UniqueFileField}
                              name="idnuevaoferta"
                              invalid={errors.idnuevaoferta && touched.idnuevaoferta}
                              disabled={!habilitar}
                              category="nueva_oferta"
                              modulo="PrePrecontractual"
                              idmodulo={proceso?.id}
                              record={proveedor?.idnuevaoferta}
                            />
                            <FormFeedback>{errors.idnuevaoferta && t(errors.idnuevaoferta)}</FormFeedback>
                          </FormGroup>
                        </td>
                      )}
                    </tr>
                    <tr style={{ borderBottom: '1px solid rgba(9, 30, 66, 0.1)' }}>
                      <td align="center">
                        {t('Anexos Adicionales')}
                      </td>
                      <td align="center">
                        {t('Documentos de Contratación y Anexos Adicionales')}
                      </td>
                      <td align="center">
                        {t('No')}
                      </td>
                      {habilitar && (
                        <td>
                          <FormGroup>
                            <Field
                              as={UniqueFileField}
                              name="iddocadicional"
                              invalid={errors.iddocadicional && touched.iddocadicional}
                              disabled={!habilitar}
                              category="contratacion_anexos"
                              modulo="PrePrecontractual"
                              idmodulo={proceso?.id}
                              record={proveedor?.iddocadicional}
                            />
                            <FormFeedback>{errors.iddocadicional && t(errors.iddocadicional)}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              as={UniqueFileField}
                              name="iddocadicional1"
                              invalid={errors.iddocadicional1 && touched.iddocadicional1}
                              disabled={!habilitar}
                              category="contratacion_anexos"
                              modulo="PrePrecontractual"
                              idmodulo={proceso?.id}
                              record={proveedor?.iddocadicional1}
                            />
                            <FormFeedback>{errors.iddocadicional1 && t(errors.iddocadicional1)}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              as={UniqueFileField}
                              name="iddocadicional2"
                              invalid={errors.iddocadicional2 && touched.iddocadicional2}
                              disabled={!habilitar}
                              category="contratacion_anexos"
                              modulo="PrePrecontractual"
                              idmodulo={proceso?.id}
                              record={proveedor?.iddocadicional2}
                            />
                            <FormFeedback>{errors.iddocadicional2 && t(errors.iddocadicional2)}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              as={UniqueFileField}
                              name="iddocadicional3"
                              invalid={errors.iddocadicional3 && touched.iddocadicional3}
                              disabled={!habilitar}
                              category="contratacion_anexos"
                              modulo="PrePrecontractual"
                              idmodulo={proceso?.id}
                              record={proveedor?.iddocadicional3}
                            />
                            <FormFeedback>{errors.iddocadicional3 && t(errors.iddocadicional3)}</FormFeedback>
                          </FormGroup>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        {(adjudicacion && (
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Acepta adjudicación')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="acepta"
                  invalid={errors.acepta && touched.acepta}
                  options={listSiNo}
                  disabled={!habilitar}
                />
                <FormFeedback>{errors.acepta && t(errors.acepta)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        ))}
        {enviado ? (
          <Alert color="success">
            {t(`Se ha enviado correctamente la información, puede cerrar esta ventana.`)}
          </Alert>
        ): (
          <>
            {habilitar && (
              <div className="clearfix">
                {!!proveedor?.idnuevaoferta && negociaciones && (
                  <ButtonCustom
                    type="button"
                    color="primary"
                    className="float-right ml-2"
                    loading={mainState.loading}
                    disabled={mainState.loading}
                    onClick={enviarPropuesta}
                  >
                    {t('Enviar propuesta')}
                  </ButtonCustom>
                )}
                {!!proveedor?.acepta && adjudicacion && (
                  <ButtonCustom
                    type="button"
                    color="primary"
                    className="float-right ml-2"
                    loading={mainState.loading}
                    disabled={mainState.loading}
                    onClick={enviarPropuesta}
                  >
                    {t('Finalizar')}
                  </ButtonCustom>
                )}
                <ButtonCustom
                  form="FormProveedor"
                  type="submit"
                  color="success"
                  loading={mainState.loading}
                  disabled={mainState.loading}
                  className="float-right ml-2"
                >
                  {t('Guardar información')}
                </ButtonCustom>
              </div>
            )}
          </>
        )}
        
      </FormikProvider>
    </Form>
  )
}

interface IFormProveedor {
  record: IProveedores | null;
  negociaciones?: boolean;
  adjudicacion?: boolean;
}

export default FormProveedor;
