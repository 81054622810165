import { getRequest, putRequest } from '../peticionesService';
import urlApi from '../../api';
import { INotificaciones } from '../../models/notificaciones/notificacionesModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerNotificaciones = async (id, leido?: any): Promise<INotificaciones[]> => {
  let data: INotificaciones[] = [];
  try {
    const url = (leido) ? `${urlApi.NOTIFICACIONES_LIST}${id}/${leido}` : `${urlApi.NOTIFICACIONES_LIST}${id}`
    const resp = await getRequest(url);
    data = resp?.data?.records?.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const leerNotificacion = async (id): Promise<boolean> => {
  const url = `${urlApi.NOTIFICATION_READ}${id}`;
  let success = false;
  try {
    const resp = await putRequest(url, {
      leido: 2,
      visto: 2
    });

    if (resp.data.code === 200) {
      success = true;
    }
  } catch (error) {
    console.error(error);
  }
  return success;
};

